<template>
	<div class="plus-pay">
		<div class="breadcrumb">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item><span style="cursor: pointer" @click="$router.push({ path: '/' })">首页</span></el-breadcrumb-item>
				<el-breadcrumb-item>PLUS会员</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="top">
			<div class="plus">
				<div><img class="img" src="../../assets/plus-icon.png" alt="" /></div>
				<div class="text1">PLUS会员年卡</div>
				<div class="text1">￥{{ price.Price }}/年</div>
				<div class="text3">￥{{ price.OldPrice }}</div>
			</div>
			<div class="privilege">
				<div class="title">会员权益</div>
				<div class="privilege-list">
					<div>
						<div><img src="../../assets/plus-icon1.png" alt="" /></div>
						<div class="text">2倍积分返利</div>
					</div>
					<div>
						<div><img src="../../assets/plus-icon1.png" alt="" /></div>
						<div class="text">PLUS会员价</div>
					</div>
				</div>
			</div>
		</div>
		<div class="invoice">
			<div class="invoice-top">
				<div>发票信息</div>
				<div class="edit" v-if="invoice" @click="handleEditInvoice">修改</div>
			</div>
			<div class="invoice-bottom" v-if="invoice">
				<div style="margin-left: 60px">
					<span v-if="invoice.InvoiceType == 2 || invoice.InvoiceType == 3">增值税普通发票</span>
					<span v-if="invoice.InvoiceType == 1">增值税专用发票</span>
					<span v-if="invoice.InvoiceType == 3" style="margin-left: 10px">个人</span>
					<span style="margin-left: 10px" v-if="invoice.InvoiceType == 2 || invoice.InvoiceType == 1">单位</span>
				</div>
				<div>
					<el-form label-width="120px">
						<el-form-item label="发票抬头">{{ invoice.InvoiceTitle }}</el-form-item>
						<template v-if="invoice.InvoiceType == 2 || invoice.InvoiceType == 1">
							<el-form-item label="纳税人识别号">{{ invoice.DutyParagraph }}</el-form-item>
							<el-form-item label="单位地址">{{ invoice.Address }}</el-form-item>
							<el-form-item label="单位电话">{{ invoice.Phone }}</el-form-item>
							<el-form-item label="开户银行">{{ invoice.BankName }}</el-form-item>
							<el-form-item label="银行账号">{{ invoice.BankNumber }}</el-form-item>
						</template>
					</el-form>
				</div>
			</div>
			<div
				class="city-span"
				style="margin:0 70px 20px 70px;
                    
                    "
				v-if="!Showinvoice && invoiceLists.length > 1"
				@click="loadingInvoice"
			>
				<div>更多发票</div>
				<div class="gd"><i :class="['iconfont', 'iconshouqi']"></i></div>
			</div>
			<div
				class="city-span"
				style="margin:0 70px 20px 70px;
                    
                    "
				v-if="Showinvoice && invoiceLists.length > 1"
				@click="loadingInvoice"
			>
				<div>收起发票</div>
				<div class=""><i :class="['iconfont', 'iconshouqi']"></i></div>
			</div>
			<div v-if="Showinvoice">
				<div class="list-city" v-for="(item, index) in invoiceLists" :key="index" style="cursor: pointer;" @click="changeCitys(item)">
					<table border="0" cellspacing="0" cellpadding="0">
						<tr>
							<td width="150">
								{{ item.RealName }}
								<span v-if="item.InvoiceType == 2 || item.InvoiceType == 1">单位</span>
								<span v-if="item.InvoiceType == 3">个人</span>
							</td>
							<td width="350">{{ item.InvoiceTitle }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="submit">
			<div>
				<div class="submit1">
					应付总额：
					<span class="text1">￥{{ price.Price }}/年</span>
				</div>
				<div class="button"><div @click="ok">同意协议 立即支付</div></div>
				<div class="submit3"><span>该PLUS会员卡的权益为一年；自缴费之日起至次年前一日24:00。比如：2020年12月19日缴费，到期日为202年12月18日晚24:00。</span></div>
			</div>
		</div>
		<el-dialog title="发票信息" class="invoice-dialog" :visible.sync="dialogVisible" width="30%">
			<div>
				<el-radio-group v-model="form.radio" size="small">
					<el-radio v-for="(item, index) in invoiceList" :key="index" :label="item.label" border :disabled="item.disabled">{{ item.title }}</el-radio>
				</el-radio-group>
			</div>
			<div class="invoice-dialog-main">
				<div>
					<el-form ref="ruleForm" :rules="rules" :model="form" label-width="120px">
						<el-form-item label="发票抬头">
							<el-radio-group v-model="form.InvoiceType" size="small" @change="changeRise">
								<el-radio :label="2" border>单位</el-radio>
								<el-radio :label="3" border v-if="form.radio == 2">个人</el-radio>
							</el-radio-group>
						</el-form-item>
						<!--个人-->
						<el-form-item label="个人名称" prop="InvoiceTitle" v-if="form.InvoiceType == 3">
							<el-input style="width: 300px" size="small" v-model="form.InvoiceTitle"></el-input>
						</el-form-item>
						<!--公司-->
						<div v-if="form.InvoiceType == 2 || form.InvoiceType == 1">
							<el-form-item label="单位名称" prop="InvoiceTitle">
								<el-autocomplete
									class="inline-input"
									v-model="form.InvoiceTitle"
									:fetch-suggestions="querySearch"
									size="small"
									style="width: 300px"
									placeholder="请输入内容"
									:trigger-on-focus="false"
									@select="handleSelect"
								></el-autocomplete>
							</el-form-item>
							<el-form-item label="纳税人识别号" prop="DutyParagraph">
								<el-input style="width: 300px" v-model="form.DutyParagraph" size="small" placeholder="请输入纳税人识别号"></el-input>
							</el-form-item>
							<el-form-item label="单位地址" prop="Address">
								<el-input style="width: 300px" v-model="form.Address" size="small" placeholder="请输入单位地址"></el-input>
							</el-form-item>
							<el-form-item label="单位电话" prop="Phone"><el-input style="width: 300px" v-model="form.Phone" size="small" placeholder="请输入单位电话"></el-input></el-form-item>
							<el-form-item label="开户银行" prop="BankName">
								<el-input style="width: 300px" v-model="form.BankName" size="small" placeholder="请输入开户银行"></el-input>
							</el-form-item>
							<el-form-item label="银行账号" prop="BankNumber">
								<el-input style="width: 300px" v-model="form.BankNumber" size="small" placeholder="请输入银行账号"></el-input>
							</el-form-item>
						</div>
						<el-form-item label="发票内容">
							<el-radio-group v-model="radio2" size="small"><el-radio :label="1" border>商品明细</el-radio></el-radio-group>
						</el-form-item>
						<el-form-item label="">
							<el-button class="ruleForm" size="mini" @click="submitForm('ruleForm')">保存</el-button>
							<el-button size="mini" @click="dialogVisible = false">取消</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>
		<el-dialog @close="handleClose" title="支付" :close-on-click-modal="false" :destroy-on-close="true" :visible.sync="payDialogVisible" width="50%">
			<div class="pay-type">
				<div class="active" @click="changePay(item, index)" v-for="(item, index) in payList" :key="index">
					<img :src="item.pic" alt="" />
					<div class="active-img" v-if="item.active"><img src="../../assets/invoice.png" alt="" /></div>
				</div>
			</div>
			<div class="pay-pic" v-show="payList[0].active">
				<div>
					<div class="pay-pic-title">推荐扫码支付：</div>
					<div class="pay-left">
						<div><div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl"></div></div>
						<div class="pay-left-text">
							<div>打开手机支付宝</div>
							<div>扫一扫继续付款</div>
						</div>
					</div>
				</div>
				<div class="pay-right">
					<!-- 支付成功请点击
                    <div class="button">
                        已扫码付款
                    </div> -->
					<div class="pay-right-bottom">
						如有疑问或需要帮助，请联系
						<el-button size="mini"  @click="toChat()">在线客服</el-button>
					</div>
				</div>
			</div>
			<div class="pay-pic" v-show="payList[1].active">
				<div>
					<div class="pay-pic-title">推荐扫码支付：</div>
					<div class="pay-left">
						<div><div class="qrcode" id="qrCodeUrl1" ref="qrCodeUrl1"></div></div>
						<div class="pay-left-text">
							<div>打开手机微信</div>
							<div>扫一扫继续付款</div>
						</div>
					</div>
				</div>
				<div class="pay-right">
					<!-- 支付成功请点击
                    <div class="button">
                        已扫码付款
                    </div> -->
					<div class="pay-right-bottom">
						如有疑问或需要帮助，请联系
						<el-button size="mini" @click="toChat()">在线客服</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { getPlusPrice, getUserInvoice, changedInvoice, WeChatPay, searchCompanyName, getCompanyDetailInfo, getOrderInfo, aliPay, creatOrder } from './service';
export default {
	data() {
		return {
			invoiceLists: [],
			Showinvoice: false,
			qr: null,
			price: [],
			rules: {
				InvoiceTitle: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				DutyParagraph: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				Address: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				Phone: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				BankName: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				BankNumber: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				]
			},
			OrderID: 0,
			form: {
				radio: 2,
				InvoiceType: 2
			},
			invoice: null,
			payList: [
				{
					pic: require('../../assets/zhifubao.png'),
					type: 2,
					active: true
				},
				{
					pic: require('../../assets/weixin.png'),
					type: 1,
					active: false
				}
			],
			payDialogVisible: false,
			invoiceList: [
				{
					title: '增值税专用发票',
					label: 1,
					disabled: false
				},
				{
					title: '增值税普通发票',
					label: 2,
					disabled: false
				}
			],
			radio2: 1,
			dialogVisible: false
		};
	},
	mounted() {
		// 获取用户信息
		getPlusPrice().then(res => {
			this.price = res.data;
		});
		getUserInvoice().then(res => {
			if (res.data[0]) {
				this.invoiceLists = res.data;
				this.invoice = res.data[0];
			}
		});
	},
	methods: {
		toChat(){
			window.open('https://mcs.hexagonmetrology.cn/im/text/0hnzjp.html')
				// this.ysf('open');
		},
		handleClose() {
			clearInterval(this.interval);
		},
		querySearch(queryString, cb) {
			if (queryString != '') {
				searchCompanyName({ name: queryString }).then(res => {
					for (const key in res.data) {
						res.data[key].value = res.data[key].name;
					}
					cb(res.data);
				});
			}
			// var restaurants = this.restaurants
			// var results = queryString
			//     ? restaurants.filter(this.createFilter(queryString))
			//     : restaurants
			// // 调用 callback 返回建议列表的数据
			// cb(results)
		},
		handleSelect(item) {
			getCompanyDetailInfo({ ID: item.id }).then(res => {
				this.form = {
					...this.form,
					DutyParagraph: res.data.taxNumber,
					Address: res.data.regLocation,
					Phone: res.data.phoneNumber
				};
			});
		},
		changeCitys(item) {
			this.Showinvoice = false;
			this.invoice = item;
		},
		loadingInvoice() {
			this.Showinvoice = !this.Showinvoice;
		},

		// 支付切换
		changePay(data, index) {
			clearInterval(this.interval);
			for (let item in this.payList) {
				this.payList[item].active = false;
			}
			this.payList[index].active = true;
			data.active = true;
			document.getElementById('qrCodeUrl').innerHTML = '';
			document.getElementById('qrCodeUrl1').innerHTML = '';
			switch (data.type) {
				case 1:
					WeChatPay({ OrderID: this.OrderID }).then(res => {
						this.qr = new QRCode(this.$refs.qrCodeUrl1, {
							text: res.data.qr_code, // 需要转换为二维码的内容
							width: 100,
							height: 100,
							colorDark: '#000000',
							colorLight: '#ffffff',
							correctLevel: QRCode.CorrectLevel.H
						});
						this.OrderID = res.data.OrderID;
					});
					this.interval = setInterval(() => {
						getOrderInfo({ OrderID: this.OrderID }).then(res => {
							if (res.data.State == 1) {
								clearInterval(this.interval);
								this.$message.success('开通成功');
								this.$router.push({
									path: `/me/plus`
								});
							}
						});
					}, 2000);
					break;
				case 2:
					aliPay({ OrderID: this.OrderID }).then(res => {
						this.qr = new QRCode(this.$refs.qrCodeUrl, {
							text: res.data.qr_code, // 需要转换为二维码的内容
							width: 100,
							height: 100,
							colorDark: '#000000',
							colorLight: '#ffffff',
							correctLevel: QRCode.CorrectLevel.H
						});
						this.OrderID = res.data.OrderID;
					});
					this.interval = setInterval(() => {
						getOrderInfo({ OrderID: this.OrderID }).then(res => {
							if (res.data.State == 1) {
								clearInterval(this.interval);
								this.$message.success('开通成功');
								this.$router.push({
									path: `/me/plus`
								});
							}
						});
					}, 2000);
					break;
			}
			data.active = true;
		},
		ok() {
			let data = {};
			if (this.invoice && this.invoice.ID) {
				data.InvID = this.invoice.ID;
			}
			creatOrder(data).then(res => {
				this.OrderID = res.data;
				this.payDialogVisible = true;
				aliPay({ OrderID: this.OrderID }).then(res => {
					this.qr = new QRCode(this.$refs.qrCodeUrl, {
						text: res.data.qr_code, // 需要转换为二维码的内容
						width: 100,
						height: 100,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					});
					this.OrderID = res.data.OrderID;
				});
				this.interval = setInterval(() => {
					getOrderInfo({ OrderID: this.OrderID }).then(res => {
						if (res.data.State == 1) {
							clearInterval(this.interval);
							this.$message.success('开通成功');
							this.$router.push({
								path: `/me/plus`
							});
						}
					});
				}, 2000);
			});
		},
		changeRise(value) {
			// 单位点击
			if (value == 2) {
				this.form.InvoiceTitle = this.InvoiceTitle;
				this.invoiceList[0].disabled = false;
			} else {
				this.InvoiceTitle = this.form.InvoiceTitle;
				this.form.InvoiceTitle = '';
				this.invoiceList[0].disabled = true;
			}
		},
		// 提交发票
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.form.radio == 1) {
						this.form.InvoiceType = 1;
					}
					this.form.IsDefault = true;
					this.dialogVisible = false;
					changedInvoice(this.form).then(() => {
						this.$message.success('操作成功');
						this.form = {
							radio: 2,
							InvoiceType: 2
						};
						location.reload();
					});
				} else {
					return false;
				}
			});
		},
		handleEditInvoice() {
			this.radio2 = 1;
			if (this.invoice) {
				if (this.invoice.InvoiceType == 1) {
					// 专用发票
					this.form = {
						...this.invoice,
						radio: 1,
						InvoiceType: 2
					};
				} else if (this.invoice.InvoiceType == 2) {
					// 专用发票
					this.form = {
						...this.invoice,
						radio: 2,
						InvoiceType: 2
					};
				} else if (this.invoice.InvoiceType == 3) {
					// 专用发票
					this.form = {
						...this.invoice,
						radio: 2,
						InvoiceType: 3
					};
				}
			}
			this.dialogVisible = true;
		}
	}
};
</script>

<style lang="less" scoped>
.pay-title {
	font-family: 'ct';
	margin: 20px;
}

.pay-pic {
	background: #fdfdfd;
	border: 1px solid #dcdcdc;
	padding: 20px;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	.pay-pic-title {
		font-family: 'ct';
		margin-bottom: 20px;
	}

	.pay-left {
		display: flex;
	}

	.pay-left-text {
		text-align: center;
		margin-left: 20px;
		color: #666666;
	}

	.pay-left-date {
		margin-top: 30px;
		font-size: 12px;
		color: #dc2310;
	}
}

.pay-right {
	width: 500px;
	text-align: center;

	.button {
		background: #f2f2f2;
		border: 1px solid #dcdcdc;
		padding: 5px 10px;
		width: fit-content;
		margin: 20px auto;
		cursor: pointer;
	}

	.pay-right-bottom {
		font-size: 12px;
		margin-top: 60px;

		span {
			color: #0097ba;
		}

		button {
			margin-left: 10px;
		}
	}
}

.pay-type {
	display: flex;

	.active {
		position: relative;
		width: 216px;
	}

	.active-img {
		width: 20px;
		height: 20px;
		position: absolute;
		bottom: 25px;
		right: 0px;
	}

	div {
		margin-right: 20px;
		cursor: pointer;
	}
}
.city-span {
	color: #0097ba;
	cursor: pointer;
	display: flex;
	div {
		margin-right: 5px;
	}
	i {
		margin-left: 10px;
		font-size: 13px;
	}
	.gd {
		transform: rotate(180deg);
		-ms-transform: rotate(180deg); /* IE 9 */
		-moz-transform: rotate(180deg); /* Firefox */
		-webkit-transform: rotate(180deg); /* Safari 和 Chrome */
		-o-transform: rotate(180deg); /* Opera */
	}
}
.list-city {
	margin: 15px 0;
	border: 1px solid #f4f4f4;
	padding: 20px 0;
	font-size: 13px;
	color: #333333;
	td {
		text-align: center;
	}
	.handle {
		cursor: pointer;
	}
	.m {
		margin: 0 5px;
	}
	.active {
		background: rgba(27, 152, 170, 0.2);
		padding: 2px 20px;
		color: #1b98aa;
	}
}
.submit {
	display: flex;
	justify-content: flex-end;
	margin-top: 50px;
	.submit1 {
		color: #333333;
		text-align: right;
		.text1 {
			font-family: 'ct';
			font-size: 26px;
			color: #dc2310;
		}
	}
	.submit3 {
		color: #999999;
		margin-top: 5px;
		span {
			color: #0097ba;
			cursor: pointer;
		}
	}
	.button {
		display: flex;
		justify-content: flex-end;
		div {
			width: 150px;
			background: #dc2310;
			color: #ffffff;
			height: 50px;
			text-align: center;
			line-height: 50px;
			border-radius: 30px;
			padding: 0 20px;
			margin: 10px 0;
			cursor: pointer;
		}
	}
}
.invoice {
	margin-top: 20px;
	box-sizing: border-box;
	border: 1px solid #f4f4f4;

	.invoice-top {
		font-family: 'ct';
		background: #f9f9f9;
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;

		.edit {
			color: #1b98aa;
			font-family: '微软雅黑';
			cursor: pointer;
		}
	}

	.invoice-bottom {
		padding: 20px;
		box-sizing: border-box;

		div {
			font-family: 'ct';
			margin: 5px;
		}
	}
}
.privilege-list {
	display: flex;
	margin-top: 20px;
	color: #333333;
	div {
		margin-right: 20px;
	}
	.text {
		margin-top: 10px;
	}
}
.privilege {
	margin-left: 80px;
}
.title {
	font-size: 18px;
	font-family: 'ct';
	color: #333333;
}
.top {
	background: #f9f9f9;
	padding: 40px;
	color: #ffffff;
	display: flex;
	.text1 {
		font-family: 'ct';
		font-size: 20px;
	}
	.text3 {
		text-decoration: line-through;
		color: #999999;
	}
}
.breadcrumb {
	margin: 20px 0;
}

.sign {
	color: #0097ba;
}
.plus-pay {
	width: 1240px;
	margin: 0 auto;
}
.plus {
	background: #2d2d2d;
	width: 200px;
	height: 200px;
	text-align: center;
	padding: 20px 0;
	border-radius: 10px;
	img {
		width: 100px;
		margin: 0 auto;
	}
}
</style>
<style scoped>
.invoice-dialog >>> .el-dialog__header {
	background: #e5e5e5;
	padding: 10px;
	font-size: 14px;
}

.invoice-dialog-main {
	margin-top: 20px;
}

>>> .el-input__inner,
>>> .el-radio--small.is-bordered {
	border-radius: 0;
}

>>> .el-radio__input {
	display: none;
}

>>> .el-radio__input.is-checked {
	position: relative;
}

>>> .el-radio.is-bordered.is-checked {
	border-color: #dc2310;
}

>>> .el-radio__input.is-checked + .el-radio__label {
	color: black;
}

>>> .el-radio__label {
	padding: 0 10px;
}

>>> .is-checked::after {
	content: '';
	display: block;
	height: 18px;
	width: 18px;
	background-image: url('../../assets/invoice.png');
	background-size: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}
</style>
