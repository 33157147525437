import { post } from '@/http'

// 获取用户基本信息
export function getUserInfo(data) {
    return new Promise((resolve, reject) => {
        post('/Users/GetUserInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 获取PLUS会员价格
export function getPlusPrice(data) {
    return new Promise((resolve, reject) => {
        post('/PlusOrder/GetPlusPrice', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
// 我的发票列表
export function getUserInvoice(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/GetUserInvoice', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 新建发票
export function changedInvoice(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/ChangedInvoice', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 新建发票
export function aliPay(data) {
    return new Promise((resolve, reject) => {
        post('/PlusOrder/AliPay', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

//  创建PLUS会员订单
export function creatOrder(data) {
    return new Promise((resolve, reject) => {
        post('/PlusOrder/CreatOrder', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

//  会员订单
export function getOrderInfo(data) {
    return new Promise((resolve, reject) => {
        post('/PlusOrder/GetOrderInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function searchCompanyName(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/SearchCompanyName', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function getCompanyDetailInfo(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/GetCompanyDetailInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function WeChatPay(data) {
    return new Promise((resolve, reject) => {
        post('/PlusOrder/WeChatPay', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
